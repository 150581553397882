import { Form, Link, useNavigation } from "@remix-run/react";
import { Image } from "@unpic/react";
import { Check, Plus } from "lucide-react";
import { cn } from "~/lib/utils";

export function RecipeCard(props: {
  slug: string;
  image: string | null;
  title: string;
  loading?: boolean;
  index?: number;
  mealPlan?: string | null;
  isLoggedIn?: boolean;
}) {
  const submission = useNavigation();
  let inMealPlan =
    props.mealPlan ||
    (submission.formData?.get("action") === "mealPlan" &&
      submission.formData?.get("recipe") === props.slug);

  if (
    submission.formData?.get("action") === "removeMealPlan" &&
    submission.formData.get("mealPlanId") === props.mealPlan
  ) {
    inMealPlan = false;
  }
  return (
    <Link
      to={`/recipe/${props.slug}`}
      className="relative min-w-[130px] w-full max-w-[320px] block overflow-hidden snap-start"
    >
      {props.isLoggedIn ? (
        <Form method="POST" className="inline">
          <input type="hidden" name="recipe" value={props.slug} />
          <input type="hidden" name="mealPlanId" value={props.mealPlan || ""} />
          <input
            type="hidden"
            name="action"
            value={props.mealPlan ? "removeMealPlan" : "mealPlan"}
          />
          <button
            onClick={(e) => e.stopPropagation()}
            name="action"
            value="cooked"
            className={cn(
              "cursor-pointer rounded-full p-1 absolute top-1 right-1",
              {
                "bg-stone-50 text-stone-800": !inMealPlan,
                "bg-stone-700 text-white": inMealPlan,
              }
            )}
          >
            {inMealPlan ? (
              <Check className="h-4 w-4" />
            ) : (
              <Plus className="h-4 w-4" />
            )}
          </button>
        </Form>
      ) : null}
      {props.image ? (
        <Image
          src={props.image}
          aspectRatio={1}
          width={320}
          className="rounded-xl shadow-md aspect-square object-cover"
          alt={props.title}
          // priority={(props?.index ?? Infinity) <= 8}
        />
      ) : (
        <div
          className={cn(
            "max-w-[320px] w-full aspect-square rounded-xl shadow-md bg-contain bg-center",
            { "animate-pulse bg-stone-800": props.loading }
          )}
          style={{
            animationDelay: `${(props.index || 0) * 50}ms`,
            backgroundImage: "url(/icons/android-chrome-512x512.png)",
          }}
        />
      )}
      <p
        className={cn(
          "whitespace-pre-wrap sm:text-lg font-medium leading-tight my-2 sm:my-4",
          { "animate-pulse w-full h-4 bg-stone-800 rounded": props.loading }
        )}
        style={{ animationDelay: `${(props.index || 0) * 50}ms` }}
      >
        {props.title}
      </p>
    </Link>
  );
}

export const recipeCardSelect = [
  "Recipe.slug",
  "Recipe.image",
  "Recipe.title",
  "MealPlan.id as mealPlan",
] as const;
