import { Link } from "@remix-run/react";
import { buttonVariants } from "~/components/ui/Button";
import { cn } from "~/lib/utils";

export function Categories({
  categories,
  category,
}: {
  categories: { slug: string; title: string }[];
  category?: string;
}) {
  return (
    <div className="px-8">
      <div className="px-4 max-w-3xl mx-auto flex gap-4 overflow-x-auto py-4">
        {categories.map((cat) => (
          <Link
            key={cat.slug}
            to={`/category/${cat.slug}`}
            className={cn(
              buttonVariants({
                variant: "primary",
                size: "sm",
                className: "rounded-full flex items-center whitespace-nowrap",
                focused: category === cat.title,
              })
            )}
          >
            {cat.title}
          </Link>
        ))}
      </div>
    </div>
  );
}
